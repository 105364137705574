/*!
 * @copyright &copy; Kartik Visweswaran, Krajee.com, 2013 - 2016
 * @version 3.5.8
 *
 * A simple yet powerful JQuery star rating plugin that allows rendering
 * fractional star ratings and supports Right to Left (RTL) input.
 *
 * For more JQuery/Bootstrap plugins and demos visit http://plugins.krajee.com
 * For more Yii related demos visit http://demos.krajee.com
 */

.rating-fa,
.rating-fa .rating-stars:before {
  padding-left: 1px;
}

.rating-container-rtl .rating-stars:before,
.rating-container-rtl:before,
.rating-container:before {
  content: attr(data-content);
}

.rating-container,
.rating-container-rtl {
  display: inline-block;
  vertical-align: middle;
}

.star-rating .clear-rating,
.star-rating-rtl .caption {
  padding-right: 5px;
}

.rating-loading {
  width: 25px;
  height: 25px;
  font-size: 0;
  color: #fff;
  background: url("loading.gif")top left no-repeat;
  border: none;
}

.rating-fa {
  font-family: FontAwesome;
}

.rating-gly,
.rating-gly-star {
  font-family: 'Glyphicons Halflings';
}

.rating-gly-star,
.rating-gly-star .rating-stars:before {
  padding-left: 2px;
}

.rating-lg .rating-gly-star,
.rating-lg .rating-gly-star .rating-stars:before {
  padding-left: 4px;
}

.rating-xl .rating-gly-star,
.rating-xl .rating-gly-star .rating-stars:before {
  padding-left: 2px;
}

.rating-active {
  cursor: default;
}

.rating-disabled {
  cursor: not-allowed;
}

.rating-uni {
  font-size: 1.2em;
  margin-top: -5px;
}

.rating-container {
  position: relative;
  color: #e3e3e3;
  overflow: hidden;
}

.rating-container .rating-stars {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #fde16d;
  transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -webkit-transition: all .25s ease-out;
}

.rating-container .rating-stars:before {
  content: attr(data-content);
  text-shadow: 0 0 1px rgba(0,0,0,.7);
}

.rating-container-rtl {
  position: relative;
  overflow: hidden;
  color: #fde16d;
}

.rating-container-rtl:before {
  text-shadow: 0 0 1px rgba(0,0,0,.7);
}

.rating-container-rtl .rating-stars {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #e3e3e3;
  transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -webkit-transition: all .25s ease-out;
}

.rating-xl {
  font-size: 4.89em;
}

.rating-lg {
  font-size: 3.91em;
}

.rating-md {
  font-size: 3.13em;
}

.rating-sm {
  font-size: 2.5em;
}

.rating-xs {
  font-size: 2em;
}

.star-rating .clear-rating,
.star-rating-rtl .clear-rating {
  color: #aaa;
  cursor: not-allowed;
  display: inline-block;
  vertical-align: middle;
  font-size: 60%;
}

.clear-rating-active {
  cursor: pointer!important;
}

.clear-rating-active:hover {
  color: #843534;
}

.star-rating .caption,
.star-rating-rtl .caption {
  color: #999;
  display: inline-block;
  vertical-align: middle;
  font-size: 55%;
}

.star-rating .caption {
  padding-left: 5px;
}

@media print {
  .rating-container,
  .rating-container-rtl .rating-stars,
  .rating-container-rtl .rating-stars:before,
  .rating-container:before {
    color: #f3f3f3!important;
  }

  .star-rating .clear-rating,
  .star-rating-rtl .clear-rating {
    display: none;
  }
}